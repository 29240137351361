.all-item-step2 {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .main-container-url-2 {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    width: fit-content;
    padding: 4px 12px;
    align-items: center;
    position: relative;
    width: 100%;
    .input-container-2 {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      Input {
        outline: none;
        border: none;
      }
      Select {
        outline: none;
        border: none;
      }
    }
    .label-2 {
      position: absolute;
      top: -18px;
      padding: 0 8px;
      background: white;
      font-size: 10px;
      color: #7e84a3;
      font-weight: 400;
      font-family: 'Poppins', sansSerif;
      line-height: 1.4375em;
      .required {
        color: red;
      }
    }
    .url-copy-origin-2 {
      color: #7e84a3;
      font: inherit;
      font-weight: 400;
      font-family: 'Poppins', sansSerif;
      line-height: 1.4375em;
      font-size: 16px;
      margin-left: 3px;
    }
    .test-feild-text-2 {
      margin-left: 20px;
      width: 450px;
      height: 40px;
      border-radius: 4px;
      font-size: 16px;
    }
  }
}

.error-msg-pro-duration {
  color: #d32f2f;
  font-weight: 400;
  font-family: 'Poppins', sansserif;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-left: 14px;
  font-size: 12px;
}

.dd-duration{
  div{
    display: flex;
    align-items: center;
  }
}

.step2-main-heading{
  display: flex;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 22px;
  align-items: center;
  font-weight: 500;
  color: #0140AA;
}

@media only screen and (min-width: 320px) and (max-width: 500px){
  .step2-main-heading{
    display: flex;
    justify-content: flex-start;
    font-family: Poppins;
    font-size: 18px;
    align-items: center;
    font-weight: 500;
    color: #0140AA;
  }
}

@media only screen and (min-width: 500px) and (max-width: 769px){
  .step2-main-heading{
    display: flex;
    justify-content: flex-start;
    font-family: Poppins;
    font-size: 20px;
    align-items: center;
    font-weight: 500;
    color: #0140AA;
    margin-left: -13px;
  }
}

.step2-heading-body{
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  margin-left: 20px;
  color: #0140AA;
  margin-right: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 500px){
  .step2-heading-body{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-left: 0px;
    color: #0140AA;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px){
  .step2-heading-body{
    margin-left: 13px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1150px){
  .step2-heading-body{
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1500px){
  .step2-heading-body{
    margin-left: 20px;
  }
}

.step2-body{
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
}

@media only screen and (min-width: 300px) and (max-width: 1045px){
  .step2-body{
      width: 100%;
      height: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
}

.step2-img-container{
  width: 50%;
  height: 600px;
}
@media only screen and (min-width: 300px) and (max-width: 1045px){
  .step2-img-container{
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 0px;
      }
}

.scope-text{
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  color: #0140AA;
}

@media only screen and (min-width: 300px) and (max-width: 500px){
  .scope-text{
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      color: #0140AA;
      }
}

@media only screen and (min-width: 500px) and (max-width: 1045px){
  .img-step2-profile{
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 0px;
      }
}

@media only screen and (min-width: 320px) and (max-width: 380px){
  .img-step2-profile{
      width: 100%;
      height: auto;
      align-items: center;
      margin-left: -5px;
      }
}

@media only screen and (min-width: 380px) and (max-width: 500px){
  .img-step2-profile{
      width: 100%;
      height: auto;
      align-items: center;
      margin-left: 10px;
      }
}

.Formrik-Container-step2{
  width: 50%;
  height: auto;
}

@media only screen and (min-width: 300px) and (max-width: 1045px){
  .Formrik-Container-step2{
      width: 100%;
      height: 100%;
      padding: 0px;
      align-items: center;
    }
}

.step2-currency{
  width: 100%;
  padding: 20px;
}

.milestone-wrapper{
  width: 100%;
  padding: 20px;
  gap: 28px;
  border-radius: 8px;
  border: 1px solid #0909093B;
  .milestone-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h2{
      color: #1B1F26B8;
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.6000000238418579px;
      text-align: left;
      text-decoration-skip-ink: none;
      margin: 0;
    }
    button{
      width: 31px;
      height: 31px;
      padding: 7.75px;
      background: none;
      gap: 0px;
      border-radius: 15.5px;
      border: 0.77px solid #FF3B30;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .milestone-form-wrapper{
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}
