.text-step3 {
  margin-top: 50%;
  display: block;
  margin-left: 10px;
}

.attachment-issue {
  margin-top: 20px;
  margin-left: 20px;
}

.main-container-step3 {
  width: 100%;
  height: 1100px;
}

@media (max-width: 1200px) {
  .main-container-step3 {
    height: fit-content;
  }
}

.all-item-step2 {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  .all-item-step2 {
    margin-top: -20px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
  .all-item-step2 {
    margin-top: -35px;
  }
}

.text-feild-step-1 {
  width: 100%;
  padding: 20px;
}

.MuiButton-root.go-to-profile-btn {
  background-color: #0140aa;
  font-size: 16px;
  text-transform: inherit;
  width: 130px;
  border-radius: 5px;
  height: 35px;
  margin-top: 10px;
  margin-left: 74%;
  color: white;
}

.MuiButton-root.go-to-profile-btn:hover {
  background-color: #0140aa;
}

@media (max-width: 550px) {
  .fRjEnz.fRjEnz {
    padding: 0px;
    min-height: 80vh;
  }

  .css-iimk3f {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0px;
    -webkit-transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: -240px;
    height: 100vh;
  }

  .css-46bh2p-MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 0px;
  }
}

.textoverflow-step-3{
  width: 130px;
  height: 22px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.new-post-editor-step-1 {
  min-height: 150px;
}

.deliverables-step3{
  width: 100%;
  padding: 0px;
}

.deliverableTitle-step3{
  width: 100%;
}

.deliverable-description-step3{
width: 100%;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
}

.specialrequirements-step3{
  width: 99%;
}

.data-upload-step3{
  width: 100%;
  margin-left: 13px;
}

.Deliverable-Container{
width: 100%;
display: flex;
align-items: center;
}

@media only screen and (min-width: 300px) and (max-width: 800px){
  .Deliverable-Container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    }
}

/* .Button-Container-step3{
 display: flex;
 width: 99%;
 justify-content: flex-end;
 gap: 15;
 align-items: center;
 margin-top: 20px;
}

@media only screen and (min-width: 400px) and (max-width: 1050px){
  .Button-Container-step3{
    width: 99%;
    height: 100%;
    }
} */

.drag-drop-upload-data{
  margin-top: 4px;
  margin-left: 11px;
}

.remote-work-step3{
  display: flex;
  align-items: center;
}

.milestone-grid-wrapper{
  padding: 20px;
  .deliverable-heading-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    h2{
      color: #1B1F26B8;
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.6000000238418579px;
      text-align: left;
      text-decoration-skip-ink: none;
      margin: 0;
    }
    button{
      width: 31px;
      height: 31px;
      padding: 7.75px;
      background: none;
      gap: 0px;
      border-radius: 15.5px;
      border: 0.77px solid #FF3B30;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    
  }

  .deliverable-fields-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

}

.dialog-wrapper{
  .dialog-title{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-content{
    width: 392px;
    text-align: center;
    h3{
      margin: 0px;
    }
    p{
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: center;
      text-decoration-skip-ink: none;
    }
    .cta-wrapper{
      display: flex;
      justify-content: center;
      gap: 16px;
      .btn{
        font-family: 'Inter';
        font-size: '16px';
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        text-decoration-skip-ink: none;
        width: 188px;
        height: 40px;
        padding: '12px';
        border: '1px solid';
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s linear;
        box-shadow: none;
        &.btn-secondary{
          background: white;
          border-color: #0140AA;
          color: #0140AA;
          &:hover{
            transform: scale(1.05);
          }
        }
        &.btn-primary{
          background: #0140AA;
          border-color: #0140AA;
          color: #FFFFFF;
          &:hover{
            transform: scale(1.05);
            box-shadow: 0px 0px 3px 1px #0000008f;
          }
        }
      }
    }
  }
}