body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #1a53b2 !important;
  stroke-linecap: round;
  /* background: linear-gradient(to right,#1A53B2,#0140AA) !important; */
  transition: stroke-dashoffset 0.5s ease 0s;
}

.popup-process {
  background-image: linear-gradient(to right, #0140aa 0%, #1a53b2 51%, #0140aa 100%) !important;
}

.cancel-btn {
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #ffffff 100%) !important;
  color: black !important;
}

.checked-Process-btn {
  background-color: grey;
}
.checked-Process-btn:hover {
  background-image: linear-gradient(to right, #0140aa 0%, #1a53b2 51%, #0140aa 100%);
}

.admin-Side-login-btn:hover {
  background-color: null !important;
}

.popup-cancel {
  background-image: linear-gradient(to right, #ff8008 0%, #ff8008 100%) !important;
}

.word-break td {
  /* word-break: break-all !important; */
}

.reset-pass-text {
  text-align: center;
}

.chat-button {
  cursor: pointer;
  width: Fill (54px) px;
  height: Hug (28px) px;
  padding: 6px 12px 6px 12px;
  gap: 4px;
  border-radius: 4px 0px 0px 0px;
  border: 0.5px 0px 0px 0px;
  opacity: 0px;
  border: 0.5px solid #0140aa;
  box-shadow: 0px 1px 5px 0px #0000001f;
  box-shadow: 0px 2px 2px 0px #00000024;
  box-shadow: 0px 3px 1px -2px #00000033;
}

.view-offer-button {
  cursor: pointer;
  width: Fill (54px) px;
  height: Hug (28px) px;
  padding: 6px 12px 6px 12px;
  gap: 4px;
  border-radius: 4px 0px 0px 0px;
  border: 0.5px 0px 0px 0px;
  opacity: 0px;
  border: 0.5px solid #0140aa;
  box-shadow: 0px 1px 5px 0px #0000001f;
  margin-left: 10px;
  box-shadow: 0px 2px 2px 0px #00000024;
  box-shadow: 0px 3px 1px -2px #00000033;
}

.text-capitalize{
  text-transform: capitalize;
}
