.pro-menu .MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label {
  font-size: 14px;
  margin-left: 7px;
}

.pro-menu .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  margin-left: 5px;
}

.pro-menu .MuiSwitch-switchBase.Mui-checked {
  color: #1976d2;
}

.pro-menu .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #1976d2;
}

.persentage-icon {
  border: 7px solid #bddffb;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  padding: 37px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0543ab;
  align-items: center;
}

.number {
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 50px;
  position: absolute;
  top: 19px;
  right: 240px; // calc(100vw - (77.06/100)*100vw);
}

.bell-icon-open {
  background-color: #accbff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bell-icon-closed {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  .number {
    height: 8px;
    width: 8px;
    background-color: #ff0000;
    border-radius: 50px;
    position: absolute;
    top: 11px;
    right: 6px;
    // top: 0px;
    // right: 5px; // calc(100vw - (77.06/100)*100vw);
  }
}

.persentage-icon{
  border: 7px solid #BDDFFB;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  padding: 37px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0543AB;
  align-items: center;
}

.notification-box {
  position: absolute;
  // overflow: scroll;
  width: 490px;
  height: 570px;
  right: 60px;
  top: 66px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21px;
    color: #000000;
    padding-top: 42px;
    padding-left: 40px;
    padding-right: 50px;
    padding-bottom: 35px;
    .notification-txt {
      font-size: 24px;
    }
    .mark-read-txt {
      font-size: 18px;
      color: #0140aa;
      cursor: pointer;
    }
  }
  .all-txt {
    padding-left: 40px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
    color: #000000;
  }
  .underline-style {
    display: flex;
    flex-direction: row;
    margin-left: 38px;
    margin-top: 10px;
    margin-right: 48px;
    align-items: baseline;
    .blue-underline {
      height: 3px;
      width: 24px;
      background-color: #0140aa;
    }
    .grey-underline {
      height: 1px;
      width: 542px;
      background-color: #a8a8a8;
    }
  }
  .notification-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 135px);
    .latest-old-txt {
      font-family: 'Poppins';
      font-weight: 400;
      line-height: 21px;
      font-size: 14px;
      margin-left: 40px;
      margin-top: 25px;
      margin-bottom: 13px;
      color: rgba(0, 0, 0, 0.6);
    }
    .notification-wrapper-container {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      margin-bottom: 15px;
      margin-left: 32px;
      margin-right: 39px;
    }
    .notification-item-container {
      height: 80px;
      width: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 8px;
      padding-right: 16px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      &:hover {
        cursor: pointer;
        background-color: #f0f0f0;
        padding-right: 11px;
        .blue-dot-border {
          border: 1px solid #a2a2a2;
          height: 18px;
          width: 18px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0px;
        }
      }
      .name-initials {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        background-color: #ccd8ee;
        border: 2px solid #eeeeee;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
        font-family: 'Arial';
        font-weight: 700;
        font-size: 14px;
        line-height: 16.1px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
      }
      .notification-desciption {
        margin-left: 23px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Arial';
        font-weight: 400;
        font-size: 14px;
        line-height: 16.1px;
        flex-grow: 1;
        .description-txt {
          color: #000000;
        }
        .posted-ago {
          color: #767676;
        }
      }
      .blue-dot-border {
        .blue-dot {
          background-color: #0140aa;
          height: 8px;
          width: 8px;
          border-radius: 50px;
        }
      }
    }
  }
}

@media screen and (min-width: 10px) and (max-width: 430px) {
  .notification-box {
    height: 100vh;
    width: 100vw;
    right: 0px;
    top: 56px;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    .notifications-header {
      padding-top: 39px;
      padding-left: 20px;
      padding-right: 16px;
      padding-bottom: 35px;
      .notification-txt {
        font-size: 22px;
      }
      .mark-read-txt {
        font-size: 16px;
      }
    }
    .all-txt {
      padding-left: 20px;
    }
    .underline-style {
      margin-left: 18px;
      margin-right: 16px;
      .grey-underline {
        width: 319px;
      }
    }
    .notification-wrapper {
      height: calc(100% - 130px);
      .latest-old-txt {
        margin-left: 23px;
        margin-top: 26px;
        margin-bottom: 11px;
      }
      .notification-item-container {
        height: 98px;
        width: auto;
        margin-bottom: 17px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 8px;
        padding-right: 13px;
        &:hover {
          padding-right: 8px;
          .blue-dot-border {
            border: 1px solid #a2a2a2;
            height: 18px;
            width: 18px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0px;
          }
        }
        .notification-desciption {
          margin-right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-family: 'Arial';
          font-weight: 400;
          font-size: 14px;
          line-height: 16.1px;
          flex-grow: 1;
        }
      }
    }
  }
}

.verified-name-ico {
  width: 18px;
}

.persentage-icon {
  border: 7px solid #bddffb;
  border-radius: 50%;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  padding: 37px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0543ab;
  align-items: center;
}
